<template>
  <div class="background">
    <div class="container">
      <p class="text m-0">
        Наразі наш сайт знаходиться в процесі розробки.
        <span v-if="!props.userData">
          <span class="log-in-word" @click="logInPage">Зареєструйся</span> та стань бета тестером.
        </span>
        <span v-else>
          Якщо побачили помилку, будь ласка, напишіть нам в
          <a href="https://t.me/Huzhva9999" class="text-primary"> телеграм. </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { getGoogleUrl } from "@/utils/getGoogleUrl";
import { defineProps } from "vue";

const props = defineProps({ userData: Object });

const logInPage = () => {
  location.href = getGoogleUrl("/");
};
</script>

<style lang="scss">
.background {
  background-color: rgb(247, 136, 63);
  display: flex;
  align-items: center;
}

.text {
  text-align: center;
  font-weight: 500;
  color: rgb(22, 22, 22);
  padding: 5px 0;
}

.log-in-word {
  font-weight: bolder;
  border-bottom: 1px solid #000000;
  cursor: pointer;
}
</style>
