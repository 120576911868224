export const formattedDate = (date) => {
  if (!date) return null;
  const splitDate = date.split("-");

  if (parseInt(splitDate[1]) < 10) {
    splitDate[1] = `0${splitDate[1]}`;
  }
  if (parseInt(splitDate[2]) < 10) {
    splitDate[2] = `0${splitDate[2]}`;
  }

  return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`;
};
