<template>
  <div class="container">
    <div class="content">
      <h1 class="content__title">Щось пішло не так :(</h1>
      <div class="content__text">
        <p>Можливо, виникла помилка, тому рекомендуємо вам спробувати увійти на сервіс через 10 хвилин</p>
        <p>Якщо це буде можливо, будь ласка, зв’яжіться з нами</p>
      </div>
      <router-link :to="{name: 'Home'}">
        <Button text="Повернутися на головну"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '../Button/Button';
export default {
  name: "ErrorComponent",
  components: {
    Button
  }
}
</script>

<style scoped lang="scss">
.content {
  min-height: 100%;
  text-align: center;
  margin-bottom: 12em;
  
  &__title {
    font-family: $secondary-font;
    font-weight: 900;
    margin-top: 3em;
    margin-bottom: 1em;
  }
  
  &__text {
    line-height: 1.5;
    margin-bottom: 20px;
  }
}
</style>