<template>
  <RentalBlank v-if="id === 'orenda'" :getTemplate="handleTemplate" :template="template" />
  <DepositBlank v-if="id === 'zavdatok'" :getTemplate="handleTemplate" :template="template" />
  <LoanBlank v-if="id === 'loan'" :getTemplate="handleTemplate" :template="template" />
  <PersonalTemplate v-if="id === 'individual'" :getTemplate="handleTemplate" :template="template" />
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
// import RentalForm from "./Rental/RentalForm.vue";
// import DepositForm from "./Deposit/DepositForm.vue";
// import LoanStart from "./Loan/LoanStart.vue";
import PersonalTemplate from "./PersonalTemplate.vue";
import { getTemplate } from "../../services/TemplateService";
import RentalBlank from "./Rental/RentalBlank.vue";
import DepositBlank from "./Deposit/DepositBlank.vue";
import LoanBlank from "./Loan/LoanBlank.vue";

const route = useRoute();
const template = ref(null);
const id = route.params.id;

const handleTemplate = (id) => getTemplate(id);
</script>

<style lang="scss">
@import "./assets/styles.scss";
</style>
