<template>
  <!-- Display content of the template -->
  <div class="template__content">
    <router-link
      to="/loan/form/"
      v-if="loanData.data.currentDoc?.status === 'Draft'"
      class="edit-btn d-flex align-items-center gap-3">
      <p class="m-0">Змінити дані</p>
      <font-awesome-icon icon="fa-solid fa-pen" />
    </router-link>
    <span>
      <span class="template__content__title">РОЗПИСКА</span>
      <br />
      <span class="template__content__title">про отримання позики</span>
      <br />
      <br />
      Дана розписка складена в м.
      <span :class="{ active: props.currentStep === 1 || currentStep === 101 }">{{
        loanData.data.form?.location || "_________"
      }}</span>
      <br />
      <br />
      Я,
      <span :class="{ active: currentStep === 2 }">{{ loanData.data.form?.username || "(ПІБ)" }}</span
      >,
      <span :class="{ active: currentStep === 3 }">
        {{ loanData.data.form?.userBirthday || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: currentStep === 4 }">{{ loanData.data.form?.userPassportSeries || "_____" }}</span>
      виданий
      <span :class="{ active: currentStep === 4 }">
        {{ loanData.data.form?.userPassportDate || "__________________" }}
      </span>
      р., зареєстрований(-a) та проживаю за адресою:
      <span :class="{ active: currentStep === 5 }"> {{ loanData.data.form?.userAddress || "__________________" }} </span
      >, одержав(ла) в борг від
      <span :class="{ active: currentStep === 102 }">
        {{ getGenitive(loanData.data.form?.secondUsername) || "(ПІБ)" }} </span
      >,
      <span :class="{ active: currentStep === 103 }">
        {{ loanData.data.form?.secondUserBirthday || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: currentStep === 104 }">
        {{ loanData.data.form?.secondUserPassportSeries || "_______ " }}
      </span>
      виданий
      <span :class="{ active: currentStep === 104 }">
        {{ loanData.data.form?.secondUserPassportDate || "__________________" }}
      </span>
      р., зареєстрований(-a) та проживаючий(-a) за адресою:
      <span :class="{ active: currentStep === 105 }">
        {{ loanData.data.form?.secondUserAddress || "____________" }} </span
      >, грошові кошти в сумі
      <span v-if="loanData.data.form?.currency === 'USD'">$ </span>
      <span v-if="loanData.data.form?.currency === 'EUR'">€ </span>
      <span :class="{ active: currentStep === 6 || currentStep === 106 }">
        {{ loanData.data.form?.loanAmountNumber || "______" }}
      </span>
      <span v-if="loanData.data.form?.currency === 'UAH'"> грн. </span> (
      <span :class="{ active: currentStep === 6 || currentStep === 106 }">
        {{ loanData.data.form?.loanAmountString || "_____________" }}
      </span>
      <span v-if="loanData.data.form?.currency === 'USD'"> доларів</span>
      <span v-if="loanData.data.form?.currency === 'EUR'"> євро</span>
      <span v-if="loanData.data.form?.currency === 'UAH'"> гривень</span>
      ) в якості позики для власних потреб. Зобов’язуюсь повернути грошові кошти в повному обсязі своєчасно до
      <span :class="{ active: currentStep === 7 || currentStep === 107 }">
        {{ loanData.data.form?.loanReturnDate || "_____________" }}
      </span>
      року. У разі недотримання цього зобов’язуюсь виплатити пеню в розмірі одного відсотка від запозиченої суми за
      кожен тиждень прострочення виплати.
      <br />
      <span>
        Розписка буде мати юридичну силу після передачі грошей від
        {{ getInitials(loanData.data.form?.secondUsername) || "ПІБ" }} до
        {{ getInitials(loanData.data.form?.username) || "ПІБ" }}
      </span>
      <br />
      <br />
      <span class="template__content__last-row">
        <span :class="{ active: currentStep === 8 || currentStep === 108 }">
          {{ loanData.data.form?.docDate || "_________" }} року
        </span>
        <div class="d-flex align-items-center justify-content-between gap-3">
          <img
            v-if="
              loanData.data.currentDoc &&
              loanData.data.currentDoc?.status !== 'Draft' &&
              loanData.data.currentDoc?.status !== 'AggredToSign'
            "
            class="template__content__signImg"
            src="@/assets/icons/sign_stamp.svg"
            alt="" />
          <!-- <img
            v-if="firstSign?.userId === loanData.data.firstUserId"
            class="template__content__signImg"
            :src="firstSign?.data"
            alt="" />
          <img
            v-else-if="firstSign?.userId === loanData.data.secondUserId"
            :src="secondSign?.data"
            class="template__content__signImg"
            alt="" /> -->
          <span :class="{ active: currentStep === 2 }" class="ms-auto">
            {{ getInitials(loanData.data.form?.username) || "____________" }}
          </span>
        </div>
      </span>
      <span class="template__content__last-row">
        <div class="support-width"></div>
        <div class="d-flex align-items-center justify-content-between gap-3">
          <!-- <img
            v-if="firstSign?.userId === loanData.data.secondUserId"
            class="template__content__signImg"
            :src="firstSign?.data"
            alt="" />
          <img
            v-else-if="firstSign?.userId === loanData.data.firstUserId"
            :src="secondSign?.data"
            class="template__content__signImg"
            alt="" /> -->
          <span :class="{ active: currentStep === 102 }" class="ms-auto">
            {{ getInitials(loanData.data.form?.secondUsername) || "____________" }}
          </span>
        </div>
      </span>
      <br />
      <span v-if="loanData.data.form?.dealCompleted">
        Розписку виконано в повному обсязі. Сторони не мають претензій одна до одної.
      </span>
      <br />
      <div class="row" v-if="loanData.data.form?.dealCompleted">
        <div class="template__content__last-row">
          {{ formattedDate(loanData.data.form?.dealCompleted) || "_________" }} року
          <div
            v-if="isParticipantSigned() === 'First signed' || isParticipantSigned() === 'All signed'"
            class="d-flex align-items-center justify-content-between gap-3">
            <!-- <img
              v-if="firstSign?.userId === loanData.data.firstUserId"
              class="template__content__signImg"
              :src="firstSign?.data"
              alt="" />
            <img
              v-else-if="firstSign?.userId === loanData.data.secondUserId"
              :src="secondSign?.data"
              class="template__content__signImg"
              alt="" /> -->
            <p class="m-0 ms-auto">{{ getInitials(loanData.data.form?.username) || "____________" }}</p>
          </div>
        </div>
        <div class="template__content__last-row">
          <div class="support-width"></div>
          <div
            v-if="isParticipantSigned() === 'All signed' || isParticipantSigned() === 'Second signed'"
            class="d-flex align-items-center justify-content-between gap-3">
            <!-- <img
              v-if="firstSign?.userId === loanData.data.secondUserId"
              class="template__content__signImg"
              :src="firstSign?.data"
              alt="" />
            <img
              v-else-if="firstSign?.userId === loanData.data.firstUserId"
              :src="secondSign?.data"
              class="template__content__signImg"
              alt="" /> -->
            <p class="m-0 ms-auto">{{ getInitials(loanData.data.form.secondUsername) || "____________" }}</p>
          </div>
        </div>
      </div>
      <br />
    </span>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useLoanStore } from "@/stores/loanStore";
import { getGenitive, getInitials } from "@/services/nameCases";
import { formattedDate } from "@/services/formattedDate";

const props = defineProps({ currentStep: Number });

const loanData = useLoanStore();
// const firstSign = ref(null);
// const secondSign = ref(null);
const firstParticipant = ref(null);
const secondParticipant = ref(null);

onMounted(() => {
  // [firstSign.value, secondSign.value] = loanData.data.form.signature;

  if (loanData.data.secondUserId) {
    [firstParticipant.value, secondParticipant.value] = loanData.data.currentDoc.participants;
  }
});

const isParticipantSigned = () => {
  if (firstParticipant.value?.status === "FinalSigned" && secondParticipant.value?.status === "FinalSigned") {
    return "All signed";
  }
  if (firstParticipant.value?.status === "FinalSigned" && secondParticipant.value?.status !== "FinalSigned") {
    return "First signed";
  }
  if (firstParticipant.value?.status !== "FinalSigned" && secondParticipant.value?.status === "FinalSigned") {
    return "Second signed";
  }
};
</script>

<style lang="scss" scoped>
.complete {
  font-family: $main-font;
}

.active {
  background-color: $bpLight-1;
}
</style>
