import { defineStore } from "pinia";

export const useLoanStore = defineStore("loanData", {
  state: () => ({
    data: {
      rozpyskaId: null,
      currentDoc: null,
      firstUserId: null,
      secondUserId: null,
      secondUserEmail: null,
      templateType: "loan",
      rozpyskaName: null,
      form: {},
    },
  }),
  persist: {
    storage: localStorage,
  },
});
