import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import router from "./router/router";
import App from "./App.vue";
import "@/assets/styles.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import posthogPlugin from "./utils/posthog";

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

/* import the fontawesome core and component */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faFacebookF,
  faGoogleDrive,
  faInstagram,
  faTelegram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCalendar,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faCopy,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faArrowRight,
  faArrowRightFromBracket,
  faBars,
  faDollarSign,
  faDownload,
  faEarthAmericas,
  faFileInvoice,
  faMobileScreen,
  faScrewdriverWrench,
  faSeedling,
  faShieldHalved,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTiktok,
  faInstagram,
  faFacebookF,
  faYoutube,
  faTelegram,
  faEnvelope,
  faAngleDown,
  faEarthAmericas,
  faShieldHalved,
  faFileInvoice,
  faClock,
  faScrewdriverWrench,
  faDollarSign,
  faMobileScreen,
  faSeedling,
  faDownload,
  faBars,
  faArrowRight,
  faPen,
  faArrowRightFromBracket,
  faCopy,
  faCircleCheck,
  faCircleXmark,
  faCalendar,
  faGoogleDrive,
  faTrash
);

import Vue3Signature from "vue3-signature";
import { faPen } from "@fortawesome/free-solid-svg-icons";

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(Vue3Signature);
app.use(posthogPlugin);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
