<template>
  <div class="container">
    <div v-if="!userData.userData" class="not-auth">Будь ласка, авторизуйтесь!</div>

    <div v-if="userData.userData" class="user-profile">
      <div class="user-profile__bio">
        <h1 class="user-profile__title">{{ greeting }}</h1>
        <p class="user-profile__subtitle">{{ subtitle }}</p>
        <router-link :to="{ name: 'LoanStart' }">
          <Button text="Заповнити розписку" />
        </router-link>
      </div>
      <div class="templates">
        <!----------- Template Cards ----------->
        <TemplateCards @some-event="callback" :showTemplate="showTemplate" :removeDoc="removeDoc" :key="componentKey" />

        <!------------- Modal ------------->
        <Teleport to="#modal">
          <Transition name="modal">
            <div v-if="isTemplateOpen" class="template-bg">
              <div class="wrapper" ref="modal">
                <button class="close-btn" @click="isTemplateOpen = false">x</button>
                <RentalTemplate v-if="type === 'orenda'" class="modal-content" />
                <DepositTemplate v-if="type === 'deposit'" class="modal-content" />
                <LoanTemplate
                  v-if="type === 'loan'"
                  class="modal-content"
                  :waitingTemplates="userTemplates.waitingTemplates" />
              </div>
            </div>
          </Transition>
        </Teleport>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import RentalTemplate from "../PdfTemplate/Rental/RentalTemplate.vue";
import DepositTemplate from "../PdfTemplate/Deposit/DepositTemplate.vue";
import LoanTemplate from "../PdfTemplate/Loan/LoanTemplate.vue";
import TemplateCards from "./TemplateCards.vue";
import { onClickOutside } from "@vueuse/core";
import { getUserInfo } from "@/services/userAuthInfo";
import { useUserData } from "@/stores/userServerData.js";
import { useUserTemplatesStore } from "@/stores/userTemplates.js";
import { putStatus } from "@/services/rozpyskaApiCalls";
import Button from "../Button/Button.vue";

const userTemplates = useUserTemplatesStore();

const componentKey = ref(0);
const greeting = ref(`З поверненням!`);
const subtitle = ref(
  "У кабінеті ви можете відслідковувати ваші розписки, що вже підписані чи очікують на ваш підпис, а також архівовані розписки"
);

const callback = async (id) => {
  await putStatus(id, userData.userData.data._doc._id, "Canceled");
  const data = await getUserInfo();
  userData.userData = data;
  componentKey.value += 1;
};

const userData = useUserData();
// ========== Getting user data from server ========== //
onMounted(async () => {
  try {
    const data = await getUserInfo();
    userData.userData = data;
    console.log(userData.userData);
  } catch (error) {
    console.log(error);
  }
});

//==================== Modal magic =================== //
const isTemplateOpen = ref(false);
const type = ref(null);
const showTemplate = (name) => {
  isTemplateOpen.value = !isTemplateOpen.value;
  type.value = name;
};

const modal = ref(null);
onClickOutside(modal, () => (isTemplateOpen.value = false));
</script>

<style lang="scss" scoped>
@import "./assets/styles.scss";
.not-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  font-size: 1.5rem;
  font-weight: 900;
  font-family: $secondary-font;
}
</style>
