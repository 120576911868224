<template>
  <!-- Display content of the template -->
  <div class="template__content">
    <span>
      <span class="template__content__title">РОЗПИСКА</span>
      <br />
      <span class="template__content__title">про отримання грошей за оренду житла</span>
      <br />
      <br />
      Дана розписка складена в м.
      <span :class="{ active: props.currentStep === 2 }"
        >{{ rentalData.form.location || "_________" }}
      </span>
      <br />
      <br />
      Я,
      <span :class="{ active: props.currentStep === 3 }">
        {{ rentalData.form.username || "(ПІБ)" }}
      </span>
      ,
      <span :class="{ active: props.currentStep === 4 }">
        {{ formattedDate(rentalData.form.userBirthday) || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: props.currentStep === 5 }">
        {{ rentalData.form.userPassportSeries || "_____" }}
      </span>
      виданий
      <span :class="{ active: props.currentStep === 6 }">
        {{ formattedDate(rentalData.form.userPassportDate) || "__________________" }}
      </span>
      р., зареєстрований(-а) та проживаю за адресою:
      <span :class="{ active: props.currentStep === 7 }">
        {{ rentalData.form.userAddress || "__________________" }}
      </span>
      , одержав(-ла) від
      <span :class="{ active: props.currentStep === 8 }">
        {{ getGenitive(rentalData.form.secondUsername) || "(ПІБ)" }}
      </span>
      ,
      <span :class="{ active: props.currentStep === 9 }">
        {{ formattedDate(rentalData.form.secondUserBirthday) || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: props.currentStep === 10 }">
        {{ rentalData.form.secondUserPassportSeries || "_______ " }}
      </span>
      виданий
      <span :class="{ active: props.currentStep === 11 }">
        {{ formattedDate(rentalData.form.secondUserPassportDate) || "__________________" }}
      </span>
      р., зареєстрований(-а) та проживаючий(-а) за адресою:
      <span :class="{ active: props.currentStep === 12 }">
        {{ rentalData.form.secondUserAddress || "____________" }}
      </span>
      , грошові кошти в сумі
      <span :class="{ active: props.currentStep === 13 }">
        {{ rentalData.form.paymentNumber || "______" }}
      </span>
      грн
      <span :class="{ active: props.currentStep === 14 }">
        {{ rentalData.form.paymentNumberCoin || "___" }}
      </span>
      коп. (
      <span :class="{ active: props.currentStep === 15 }">
        {{ rentalData.form.paymentString || "_____________" }}
      </span>
      гривень
      <span :class="{ active: props.currentStep === 16 }">
        {{ rentalData.form.paymentStringCoin || "_______" }}
      </span>
      копійок) за оренду житла за
      <span :class="{ active: props.currentStep === 17 }">
        {{ rentalData.form.rentalPaymentDate || "______" }}
      </span>
      місяць 2023 року, а саме квартири (будинку, тощо), що знаходиться за адресою
      <span :class="{ active: props.currentStep === 18 }">
        {{ rentalData.form.rentalAddress || "_____________" }}
      </span>
      . Будь-яких майнових претензій до
      <span>{{ getGenitive(rentalData.form.secondUsername) || "(ПІБ)" }}</span>
      не маю, розрахунок проведено повністю.
      <br />
      <span>
        Розписка буде мати юридичну силу після передачі грошей від
        {{ getInitials(rentalData.form.secondUsername) || "ПІБ" }} до
        {{ getInitials(rentalData.form.username) || "ПІБ" }}
      </span>
      <br />
      <br />
      <span class="template__content__last-row">
        <span :class="{ active: props.currentStep === 19 }">
          {{ formattedDate(rentalData.form.docDate) || "_________" }} року
        </span>
        <img class="template__content__signImg" :src="rentalData.form.userSignature" alt="" />
        <span :class="{ active: props.currentStep === 3 }">
          {{ getInitials(rentalData.form.username) || "_______________________" }}
        </span>
      </span>
      <span class="template__content__last-row">
        <div class="support-width"></div>
        <img class="template__content__signImg" :src="rentalData.form.secondUserSignature" alt="" />
        <span :class="{ active: props.currentStep === 8 }">
          {{ getInitials(rentalData.form.secondUsername) || "_______________________" }}
        </span>
      </span>
      <br />
      <span v-if="rentalData.form.completedDate">
        Розписку виконано в повному обсязі. Сторони не мають претензій одна до одної.
      </span>
      <span v-if="!rentalData.form.completedDate" class="complete">
        <router-link to="/template/orenda">
          <Button text="Підтвердити виконану розписку" />
        </router-link>
      </span>
      <br />
      <div v-if="rentalData.form.completedDate" class="row">
        <br />
        <span class="template__content__last-row">
          {{ formattedDate(rentalData.form.completedDate) || "_________" }}
          року
          <img class="template__content__signImg" :src="rentalData.form.userSignature" alt="" />
          <span>{{ getInitials(rentalData.form.username) || "_______________" }}</span>
        </span>
        <span class="template__content__last-row">
          <div class="support-width"></div>
          <img
            class="template__content__signImg"
            :src="rentalData.form.secondUserSignature"
            alt=""
          />
          <span>{{ getInitials(rentalData.form.secondUsername) || "_______________" }}</span>
        </span>
      </div>
      <br />
    </span>
  </div>
</template>

<script setup>
import { defineProps } from "vue"
import { useRentalStore } from "@/stores/rentalStore"
import { getGenitive, getInitials } from "@/services/nameCases"
import Button from "@/components/Button/Button.vue"
import { formattedDate } from "@/services/formattedDate"

const rentalData = useRentalStore()

const props = defineProps({
  currentStep: {
    type: Number,
  },
})
</script>

<style lang="scss" scoped>
@import "../assets/styles.scss";

.complete {
  font-family: $main-font;
}

.active {
  background-color: $bpLight-1;
}
</style>
