<template>
  <div class="google-container">
    <div class="social-auth">
      <a :href="getGoogleUrl(from)" class="google-auth">
        <img :src="GoogleLogo" alt="Google Logo" />
        <span>Увійти за допомогою Google</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import GoogleLogo from "./assets/google.svg";
import { getGoogleUrl } from "../../utils/getGoogleUrl";

const from = "/";
</script>

<style lang="scss" scoped>
.google-auth {
  max-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $natural;
  border-radius: 30px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: #0f0d15;
}

.google-auth img {
  height: 2em;
}
.google-auth span {
  margin-left: 1em;
  font-size: 1rem;
  font-weight: 400;
}

.google-auth:hover {
  box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
}
</style>
