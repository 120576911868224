import axios from "axios";

const calls = {
  getRozpyskaData: async function (docId, userId) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api/rozpyska/${docId}`, {
        headers: {
          "User-Id": userId,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  // Post request to Drafts
  postDraft: async function (data, userId, participantsArr) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/rozpyska`,
        {
          type: data.templateType,
          userId: userId,
          participants: participantsArr,
          name: data.rozpyskaName,
          data: JSON.stringify(data),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Post draft: ", response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // Change data in Drafts
  putData: async function (docId, userId, data) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/rozpyska/${docId}/data`,
        {
          userId: userId,
          data: JSON.stringify(data),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Put data: ", response);
    } catch (error) {
      console.error(error);
    }
  },
  // Change status in Drafts
  putStatus: async function (docId, userId, status) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/rozpyska/${docId}/status`,
        {
          userId: userId,
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Put status: ", response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  postSavedDocument: async function (docId, formData, userId) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api/saved-document/${docId}`, formData, {
        headers: {
          "User-Id": userId,
        },
      });
      console.log("postSavedDocument: ", response);
    } catch (error) {
      console.error(error);
    }
  },
  postSigned: async function (docId, userId) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/signed-document/${docId}`,
        {
          userId: userId,
        },
        {
          headers: {
            "X-Document-Request-Trace-Id": crypto.randomUUID(),
          },
        }
      );
      console.log(response.data.deeplink);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  moneyPassed: async function (docId, userId, status) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/rozpyska/${docId}/status`,
        {
          userId: userId,
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Money passed: ", response);
    } catch (error) {
      console.error(error);
    }
  },
};

export const getRozpyskaData = calls.getRozpyskaData;
export const postDraft = calls.postDraft;
export const putData = calls.putData;
export const putStatus = calls.putStatus;
export const postSigned = calls.postSigned;
export const postSavedDocument = calls.postSavedDocument;
export const moneyPassed = calls.moneyPassed;
