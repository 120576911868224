// Facade for everything that will be exported by the module.
const facade = {
  /**
   * Resolves the Genitive case for the provided name.
   */
  getGenitive: function (name) {
    if (!name || !name.length) return null;

    let names = name.split(" ");
    names = names.filter((name) => name !== "");

    if (name.endsWith("ич")) {
      let modifiedNames = names.map((name) => {
        let builderArray = name.split("");

        if (name.endsWith("а")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("и");
        } else if (name.endsWith("я")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("і");
        } else if (name.endsWith("о")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("а");
        } else if (name.endsWith("е")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("я");
        } else if (name.endsWith("и")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("ів");
        } else if (name.endsWith("ь")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("я");
        } else if (name.endsWith("ій")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("я");
        } else {
          builderArray.push("а");
        }

        return builderArray.join("");
      });

      return modifiedNames.join(" ");
    }
    // Females names
    let femSurname = names[0];

    if (femSurname.endsWith("а") || femSurname.endsWith("я")) {
      let builderArray = femSurname.split("");
      builderArray = builderArray.slice(0, -1);
      builderArray.push("и");
      femSurname = builderArray.join("");
    }

    const namesWithoutSurname = names.slice(1);

    let modifiedNames = namesWithoutSurname.map((femName) => {
      let builderArray = femName.split("");
      if (femName.endsWith("а")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("и");
      } else if (femName.endsWith("ія")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ї");
      } else if (femName.endsWith("я")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("і");
      } else if (femName.endsWith("і")) {
        builderArray.push("ї");
      } else if (femName.endsWith("ь")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("і");
      } else {
        builderArray.push("і");
      }

      return builderArray.join("");
    });

    const result = femSurname + " " + modifiedNames.join(" ");

    return result;
  },

  getInitials: function (name) {
    if (!name || !name.length) return null;
    let names = name.split(" ");

    names = names.filter((name) => name !== "");

    let surname = names[0];
    let initials = names
      .slice(1)
      .map((name) => name[0] + ".")
      .join(" ");

    return names[1] && names[2] ? surname + " " + initials : surname;
  },

  /**
   * Resolves the Dative case for the provided name.
   */
  getDative: function (name) {
    if (!name || !name.length) return null;

    let names = name.split(" ");

    if (name.endsWith("ич")) {
      let modifiedNames = names.map((name) => {
        let builderArray = name.split("");

        if (name.endsWith("а") || name.endsWith("я")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("і");
        } else if (name.endsWith("о")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("у");
        } else if (name.endsWith("е")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("ві");
        } else if (name.endsWith("и")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("ям");
        } else if (name.endsWith("ь")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("ю");
        } else if (name.endsWith("й")) {
          builderArray = builderArray.slice(0, -1);
          builderArray.push("ю");
        } else {
          builderArray.push("у");
        }

        return builderArray.join("");
      });

      return modifiedNames.join(" ");
    }
    // feminitive case
    let femSurname = names[0];

    if (femSurname.endsWith("а") || femSurname.endsWith("я")) {
      let builderArray = femSurname.split("");
      builderArray = builderArray.slice(0, -1);
      builderArray.push("і");
      femSurname = builderArray.join("");
    }

    const namesWithoutSurname = names.slice(1);

    let modifiedNames = namesWithoutSurname.map((name) => {
      let builderArray = name.split("");

      if (name.endsWith("а")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("і");
      } else if (name.endsWith("я")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ї");
      } else if (name.endsWith("е")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ві");
      } else if (name.endsWith("и")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ям");
      } else if (name.endsWith("ь")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ю");
      } else if (name.endsWith("й")) {
        builderArray = builderArray.slice(0, -1);
        builderArray.push("ю");
      } else {
        // builderArray.push("у");
      }

      return builderArray.join("");
    });
    const result = femSurname + " " + modifiedNames.join(" ");
    return result;
  },

  /**
   * Resolves the Vocative case for the provided name.
   */
  getVocative: function (name) {
    if (!name || !name.length) return null;

    let builderArray = name.split("");

    if (name.endsWith("о")) {
      builderArray = builderArray.slice(0, -1);
      builderArray.push("е");
    } else if (name.endsWith("е")) {
      builderArray = builderArray.slice(0, -1);
      builderArray.push("у");
    } else if (name.endsWith("я")) {
      builderArray = builderArray.slice(0, -1);
      builderArray.push("ю");
    } else if (name.endsWith("й")) {
      builderArray = builderArray.slice(0, -1);
      builderArray.push("ю");
    } else {
      builderArray.push("е");
    }

    return builderArray.join("");
  },
};

// Validation methods export.
export const getGenitive = facade.getGenitive;
export const getDative = facade.getDative;
export const getVocative = facade.getVocative;
export const getInitials = facade.getInitials;

// Default export to supress warnings.
export default facade;
