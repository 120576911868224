const axios = require("axios");

export function getTemplate(templateName) {
  axios
    .request({
      url: `/api/templates/${templateName}`,
      method: "GET",
      responseType: "blob",
    })
    .then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${templateName}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
    });
}
