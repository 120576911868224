import { defineStore } from "pinia"

export const useUserTemplatesStore = defineStore("userTemplates", {
  state: () => ({
    waitingTemplates: [],
    activeTemplates: [],
    completedTemplates: [],
    uncompletedTemplates: [],
  }),
  persist: {
    storage: localStorage,
  },
})
