<template>
  <main class="main">
    <div class="hero container">
      <div class="hero-section">
        <div class="hero-section-body">
          <h1 class="hero-section-body-title">Rozpyska</h1>
          <div class="hero__body">
            <p class="hero__body-text">
              Український сервіс, який допоможе тобі створювати цифрові розписки, відслідковувати їх перебіг, зберігати
              і архівувати усі важливі документи в одному місці
            </p>
            <p class="hero__body-text fw-semibold">Заповніть шаблон розписки для передачі грошей в борг прямо зараз</p>
          </div>
          <div class="hero-section-buttons">
            <router-link :to="{ name: 'LoanStart' }">
              <Button text="Заповнити розписку" />
            </router-link>
            <router-link to="/receipt-info">
              <Button
                style="
                   {
                    padding: 0 25px;
                    background-color: white;
                    color: gray;
                    border: 1px solid gray;
                  }
                "
                text="Що таке розписка?" />
            </router-link>
          </div>
        </div>
        <img class="hero-section-image" src="./assets/images/money-bag.png" alt="" />
      </div>
      <h1 class="hero-subtitle">Також завантажте інші шаблони розписок</h1>

      <div class="d-flex justify-content-center flex-wrap" style="margin-top: 120px; gap: 80px 45px">
        <!-- Router pass the parameters from the local data -->
        <router-link
          style="text-decoration: none; color: #0f0d15; line-height: 1.2"
          v-for="card in cards"
          :key="card.id"
          :to="{ name: 'Template', params: { id: card.name } }">
          <Card @click="clearStorage" :title="card.cardTitle" :text="card.cardText" :imageUrl="card.imgTitle" />
        </router-link>
      </div>
    </div>
    <AboutUs id="about" />
    <!-- <Principles />
    <OurClients /> -->
  </main>
</template>

<script setup>
import { ref } from "vue";
import Button from "../Button/Button.vue";
import Card from "./Card/MainCard.vue";
import AboutUs from "./AboutUs.vue"; 

const clearStorage = () => {
  localStorage.clear();
};

const cards = ref([
  {
    name: "orenda",
    cardTitle: "Оренда житла",
    cardText: "Шаблон розписки для оренди житла",
    imgTitle: "card-img-1.png",
    id: 1,
  },
  {
    name: "zavdatok",
    cardTitle: "Передача завдатку",
    cardText: "Шаблон розписки для передачі завдатку при купівлі авто, нерухомості",
    imgTitle: "card-img-2.png",
    id: 2,
  },
  {
    name: "loan",
    cardTitle: "Гроші в борг",
    cardText: "Шаблон розписки для отримання грошей в борг",
    imgTitle: "card-img-3.png",
    id: 3,
  },
  {
    name: "individual",
    cardTitle: "Загальний шаблон",
    cardText: "Універсальний шаблон розписки",
    imgTitle: "card-img-4.png",
    id: 4,
  },
]);
</script>

<style scoped lang="scss">
@import "./assets/styles.scss";

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    // gap: 12em;
    justify-content: space-between;
  }

  &-body {
    order: 1;
    text-align: center;

    @media (min-width: 768px) {
      width: 45%;
      order: -1;
      text-align: left;
    }

    &-title {
      text-transform: uppercase;
      font-family: $secondary-font;
      font-size: 3.5rem;
      font-weight: 900;
      margin-bottom: 0.5em;
    }
  }

  &-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    @media (min-width: 1240px) {
      flex-direction: row;
    }
  }

  &-image {
    width: 80%;
    order: -1;
    @media (min-width: 768px) {
      width: 35%;
      order: 1;
    }
  }
}

.hero-subtitle {
  font-family: $secondary-font;
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  margin-top: 120px;
}
</style>
