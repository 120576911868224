import axios from "axios";

export const getUserInfo = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api/my-profile`);
    return await response;
  } catch (error) {
    console.error(error);
  }
};
