<template>
  <main class="container">
    <section class="info">
      <article class="info__block">
        <div class="info__headline">
          <img class="logo-diia" src="./Logo_diia.png" alt="" />
          <h2 class="info__block__title">Що таке інтеграція Дія Підпису?</h2>
        </div>
        <p class="info__block__text">
          Інтеграція Дія Підпису в електронних документах надає юридичну силу розпискам онлайн. Це важливий елемент для
          захисту документів в електронному вигляді та для забезпечення їх правової ваги.
        </p>
        <p class="info__block__text">
          Перевірити Дія. Підпис можна на офіційному сайті
          <a href="#" @click="openLink('https://ca.diia.gov.ua/verify')" class="text-primary border-bottom">
            "ДІЯ" | Кваліфікований електронний підпис
          </a>
        </p>
        <div class="info__block__separator"></div>
      </article>

      <!-- <article class="info__block">
        <h2 class="info__block__title">Як зробити юридично значущий підпис в електронних документах?</h2>
        <p class="info__block__text">
          Щоб зробити підпис в електронних документах юридично значущим, необхідно мати електронний підпис, який
          відповідає вимогам законодавства. Електронний підпис може бути встановлений за допомогою різних технологій,
          таких як ЕЦП (електронний цифровий підпис) або КЕП (кваліфікований електронний підпис).
        </p>
        <div class="info__block__separator"></div>
      </article>

      <article class="info__block">
        <h2 class="info__block__title">Методи надання електронного підпису</h2>
        <p class="info__block__text">
          Існують різні методи надання підпису. Наприклад, підпис може бути наданий шляхом введення пароля або за
          допомогою сканера відбитка пальця. Однак, у більшості випадків, юридично значущим вважається тільки ЕЦП,
          оскільки він забезпечує більш високий рівень захисту документа від незаконного втручання.
        </p>
        <div class="info__block__separator"></div>
      </article>

      <article class="info__block">
        <h2 class="info__block__title">Юридична сила та можливості використання електронної розписки</h2>
        <p class="info__block__text">
          Якщо розписка онлайн надана за допомогою електронного підпису, вона може мати ту ж юридичну вагу, що й
          звичайна розписка, яка надана в паперовому вигляді. Це означає, що розписка може бути використана як доказ у
          судовому процесі або при укладанні договорів.
        </p>
      </article> -->
    </section>
  </main>
</template>

<script setup>
const openLink = (link) => window.open(link, "_blank");
</script>

<style lang="scss" scoped>
main {
  margin: 70px auto;
}
.logo-diia {
  width: 45px;
}
.info {
  max-width: 700px;

  &__headline {
    display: flex;
    align-items: center;
    gap: 1.5em;
  }

  &__block {
    flex-basis: 45%;
    // margin-bottom: 2rem;

    &__title {
      font-family: $secondary-font;
      font-size: 1.5rem;
      font-weight: 900;
      line-height: 1.25;
    }

    &__text {
      font-weight: 400;
      line-height: 1.75;
      // margin-bottom: 1em;
      margin-top: 1rem;
    }

    &__separator {
      width: 100%;
      height: 2px;
      border-radius: 5px;
      background-color: $bpLight-2;
    }
  }
}
</style>
