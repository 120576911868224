import { createRouter, createWebHistory } from "vue-router";

import MainPage from "../components/MainPage/MainPage";
import PdfTemplate from "../components/PdfTemplate/PdfTemplate";
import UserCabinet from "../components/UserCabinet/UserCabinet";
import Error from "../components/Error/Error";

export default createRouter({
  history: createWebHistory(),
  scrollBehavior: function(to) {
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/",
      name: "Home",
      component: MainPage,
      alias: "/",
    },
    {
      path: "/template/:id",
      name: "Template",
      component: PdfTemplate,
    },
    {
      path: "/loan",
      name: "LoanStart",
      component: () => import("../components/PdfTemplate/Loan/LoanStart.vue"),
    },
    {
      path: "/loan/sign/:id",
      name: "LoanSign",
      component: () => import("../components/PdfTemplate/Loan/LoanSign.vue"),
      props: true,
    },
    {
      path: "/loan/form/",
      name: "LoanForm",
      component: () => import("../components/PdfTemplate/Loan/LoanForm.vue"),
      props: true,
    },
    {
      path: "/policy",
      name: "PrivacyPolicy",
      component: () => import("../components/Info/PrivacyPolicy.vue"),
    },
    {
      path: "/download-success",
      name: "DownloadSuccess",
      component: () => import("../components/Info/DownloadSuccess.vue"),
    },
    {
      path: "/download-failed",
      name: "DownloadFailed",
      component: () => import("../components/Info/DownloadFailed.vue"),
    },
    {
      path: "/receipt-info",
      name: "ReceiptInfo",
      component: () => import("../components/Info/ReceiptInfo.vue"),
    },
    {
      path: "/profile",
      name: "UserCabinet",
      component: UserCabinet,
    },
    {
      path: "/:patchMatch(.*)*",
      name: "not-found",
      component: Error,
    },
  ],
});
