import { defineStore } from "pinia"

export const useDepositStore = defineStore("depositData", {
  state: () => ({
    form: {
      templateType: "deposit",
      templateName: "Отримання завдатку",
      location: "",
      username: "",
      usernameInitials: "",
      userBirthday: "",
      userPassportSeries: "",
      userPassportDate: "",
      userAddress: "",
      secondUsername: "",
      secondUserInitials: "",
      secondUserBirthday: "",
      secondUserPassportSeries: "",
      secondUserPassportDate: "",
      secondUserAddress: "",
      loanAmountNumber: "",
      loanAmountNumberCoin: "",
      loanAmountString: "",
      loanAmountStringCoin: "",
      apartmentAddres: "",
      purchaseDate: "",
      depositFine: "",
      docDate: "",
      userSignature: "",
      secondUserSignature: "",
      completedDate: "",
    },
  }),
  persist: {
    storage: localStorage,
  },
})
