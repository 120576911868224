import { defineStore } from "pinia"

export const useRentalStore = defineStore("rentalData", {
  state: () => ({
    form: {
      templateType: "orenda",
      templateName: "",
      location: "",
      username: "",
      userBirthday: "",
      userPassportSeries: "",
      userPassportDate: "",
      userAddress: "",
      secondUsername: "",
      secondUserBirthday: "",
      secondUserPassportSeries: "",
      secondUserPassportDate: "",
      secondUserAddress: "",
      paymentNumber: "",
      paymentNumberCoin: "",
      paymentString: "",
      paymentStringCoin: "",
      rentalPaymentDate: "",
      rentalAddress: "",
      docDate: "",
      userSignature: "",
      secondUserSignature: "",
      completedDate: "",
    },
  }),
  persist: {
    storage: localStorage,
  },
})
