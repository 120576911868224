// components/CookieBanner.vue
<template>
  <div class="banner">
    <p>
      Ми використовуємо файли cookie, щоб забезпечити вам найкращий досвід роботи на нашому веб-сайті. Файли cookie
      дозволяють нам надавати вам основні функції, такі як безпека, керування мережею та доступність
    </p>
    <button class="btn btn-primary me-3" @click="acceptCookies">Прийняти все</button>
    <!-- <button class="btn btn-outline-primary" @click="declineCookies"></button> -->
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  methods: {
    acceptCookies() {
      this.$posthog.opt_in_capturing();
      this.$emit("hideBanner");
    },
    declineCookies() {
      this.$posthog.opt_out_capturing();
      this.$emit("hideBanner");
    },
  },
};
</script>

<style scoped>
.banner {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
</style>
