<template>
  <div class="wrap">
    <div class="hero">
      <Header />
      <Suspense>
        <router-view :key="$route.path"></router-view>
      </Suspense>
      <CookieBanner v-if="showBanner" @hideBanner="showBanner = false" />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header/Header.vue";
import CookieBanner from "./components/CookieBanner/CookieBanner.vue";
import Footer from "./components/Footer/Footer.vue";

export default {
  name: "App",
  data: function () {
    return {
      showBanner: !(this.$posthog.has_opted_out_capturing() || this.$posthog.has_opted_in_capturing()),
    };
  },
  components: {
    Header,
    CookieBanner,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;900&display=swap");

@import "./assets/styles.scss";
</style>
