<template>
  <div class="accordion" id="accordionExample">
    <!-- Waiting templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne">
          Розпочаті розписки
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="d-flex flex-column gap-3 accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="userDocsDraft?.length === 0">
            <div>У вас немає розписок</div>
          </router-link>

          <div v-for="doc in userDocsDraft" :key="doc?._id">
            <div class="d-flex justify-content-between align-items-center user-profile__card">
              <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
                <div class="d-flex gap-3">
                  <img :src="require('./images/pen-line.svg')" alt="icon" />
                  <p style="margin: 0">
                    {{ doc.rozpyskaName }}
                  </p>
                </div>
              </router-link>
              <font-awesome-icon
                class="me-2 m-0 delete-btn"
                icon="fa-solid fa-trash"
                @click="$emit('someEvent', doc._id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Waiting templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo">
          Очікують на підпис
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="d-flex flex-column gap-3 accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="pendingForSign?.length === 0">
            <div>У вас немає розписок</div>
          </router-link>

          <div v-for="doc in pendingForSign" :key="doc._id">
            <div
              v-if="doc?.status === 'PendingForSign'"
              class="d-flex justify-content-between align-items-center user-profile__card">
              <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
                <div class="d-flex gap-3">
                  <img :src="require('./images/pen-line.svg')" alt="icon" />
                  <p style="margin: 0">
                    {{ doc.rozpyskaName }}
                  </p>
                </div>
              </router-link>
              <font-awesome-icon
                class="me-2 m-0 delete-btn"
                icon="fa-solid fa-trash"
                @click="$emit('someEvent', doc._id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pending for active table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree">
          Очікують на активацію
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="templates__cards accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="pendingForActive?.length === 0">
            <div>У вас немає активних розписок</div>
          </router-link>

          <div v-for="doc in pendingForActive" :key="doc._id">
            <div
              v-if="doc?.status === 'PendingForActive'"
              class="d-flex justify-content-between user-profile__card mb-3">
              <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
                <div class="d-flex gap-3">
                  <img :src="require('./images/pen-line.svg')" alt="icon" />
                  <p style="margin: 0">
                    {{ doc.rozpyskaName }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Active templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
          aria-expanded="false"
          aria-controls="collapseFour">
          Активні розписки
        </button>
      </h2>
      <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="templates__cards accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="active?.length === 0">
            <div>У вас немає активних розписок</div>
          </router-link>

          <div v-for="doc in active" :key="doc._id">
            <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
              <div v-if="doc?.status === 'Active'" class="d-flex gap-3 mb-3 user-profile__card">
                <img :src="require('./images/pen-line.svg')" alt="icon" />
                <p style="margin: 0">
                  {{ doc.rozpyskaName }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Pending For Final Sign templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
          aria-expanded="false"
          aria-controls="collapseFive">
          Очікують на фінальний підпис
        </button>
      </h2>
      <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="templates__cards accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="pendingForFinalSign?.length === 0">
            <div>У вас немає виконаних розписок, що очікують на підпис</div>
          </router-link>

          <div v-for="doc in pendingForFinalSign" :key="doc._id">
            <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
              <div v-if="doc?.status === 'PendingForFinalSign'" class="d-flex gap-3 mb-3 user-profile__card">
                <img :src="require('./images/pen-line.svg')" alt="icon" />
                <p style="margin: 0">
                  {{ doc.rozpyskaName }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Completed templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
          aria-expanded="false"
          aria-controls="collapseSix">
          Виконані розписки
        </button>
      </h2>
      <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="templates__cards accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="done?.length === 0">
            <div>У вас немає виконаних розписок</div>
          </router-link>

          <div v-for="doc in done" :key="doc._id">
            <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
              <div v-if="doc?.status === 'Done'" class="user-profile__card mb-3">
                <div class="d-flex gap-3">
                  <img :src="require('./images/pen-line.svg')" alt="icon" />
                  <p style="margin: 0">
                    {{ doc.rozpyskaName }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Uncompleted templates table -->
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed fw-bold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
          aria-expanded="false"
          aria-controls="collapseSeven">
          Невиконані розписки
        </button>
      </h2>
      <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="templates__cards accordion-body">
          <router-link to="/" style="text-decoration: none; color: inherit" v-if="notDone?.length === 0">
            <div>У вас немає не виконаних розписок</div>
          </router-link>

          <div v-for="doc in notDone" :key="doc._id">
            <router-link :to="{ name: 'LoanSign', params: { id: doc?._id } }">
              <div v-if="doc?.status === 'NotDone'" class="d-flex gap-3 user-profile__card">
                <img :src="require('./images/pen-line.svg')" alt="icon" />
                <p style="margin: 0">
                  {{ doc.rozpyskaName }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserData } from "@/stores/userServerData";
import { onMounted, ref } from "vue";

const userData = useUserData();
const userDocs = ref([]);
const userDocsDraft = ref([]);
const pendingForSign = ref([]);
const pendingForActive = ref([]);
const active = ref([]);
const pendingForFinalSign = ref([]);
const done = ref([]);
const notDone = ref([]);

onMounted(async () => {
  userDocs.value = userData.userData.data.rozpyskas;
  userDocsDraft.value = userDocs.value.filter((doc) => doc?.status === "Draft");
  pendingForSign.value = userDocs.value.filter((doc) => doc?.status === "PendingForSign");
  pendingForActive.value = userDocs.value.filter((doc) => doc?.status === "PendingForActive");
  active.value = userDocs.value.filter((doc) => doc?.status === "Active");
  pendingForFinalSign.value = userDocs.value.filter((doc) => doc?.status === "PendingForFinalSign");
  done.value = userDocs.value.filter((doc) => doc?.status === "Done");
  notDone.value = userDocs.value.filter((doc) => doc?.status === "NotDone");
});
</script>

<style lang="scss" scoped>
@import "./assets/styles.scss";
.delete-btn:hover {
  box-shadow: 0px 5px 10px 0px rgba(158, 153, 218, 0.6);
}

.delete-btn:active {
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}
</style>
