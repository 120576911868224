<template>
  <InfoBanner :userData="userData" />
  <div class="container">
    <header>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <router-link to="/" @click="closeNav" class="navbar-brand navigation-title">
            <img src="@/assets/icons/logo_rozpyska.svg" alt="" width="50" class="d-inline-block" />
            Rozpyska
          </router-link>
          <button
            class="navbar-toggler"
            style="box-shadow: none; border: none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <Transition name="fade">
            <div
              class="navbar-collapse collapse justify-content-end"
              id="navbarNavAltMarkup"
              :class="{ collapse: isVisible }">
              <ul class="navbar-nav d-flex align-items-center gap-4">
                <li vi-if="userDataLoaded" style="font-size: 1.125rem" @click="closeNav">
                  <a vi-if="userDataLoaded" href="/#diia" class="nav-link active"> Про Дія.Підпис </a>
                </li>
                <li v-if="userData" style="font-size: 1.125rem" @click="closeNav">
                  <router-link 
                    to="/profile"
                    class="nav-link active"
                    :class="{currenttab: isInProfile}">
                    Мої розписки
                  </router-link>
                </li>
                <li v-if="!userData && userDataLoaded" style="font-size: 1.125rem">
                  <GAButton class="nav-link active" />
                </li>
                <li v-if="userData" style="font-size: 1.125rem">
                  <!-- <router-link to="/profile" class="nav-link active"> -->
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    class="profile-photo"
                    :src="userData.data._doc.photo"
                    alt="" />
                  <!-- </router-link> -->
                </li>
              </ul>
            </div>
          </Transition>
        </div>
      </nav>

      <div
        v-if="userData"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <p class="m-0">{{ userData.data?._doc.email }}</p>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <h4 style="font-weight: 700; margin: 10px 0">Вітаємо, {{ userData.data?._doc.name }}</h4>
          <div class="d-flex align-items-center gap-3" role="button" @click="handleLogout">
            <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
            <p class="m-0">Вийти з облікового запису</p>
          </div>
        </div>
      </div>

      <div v-if="userData" class="collapse" id="collapseExample">
        <div class="d-flex flex-column align-items-end">
          <div style="border-radius: 16px; padding: 10px 30px; background-color: #f0f0f0">
            <p style="text-align: center">{{ userData.data?._doc.email }}</p>
            <h4 style="text-align: center; font-weight: 700; margin: 10px 0">
              Вітаємо, {{ userData.data?._doc.name }}
            </h4>
            <div
              class="d-flex align-items-center gap-3"
              style="padding: 10px 30px; cursor: pointer"
              @click="handleLogout">
              <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
              <p class="m-0">Вийти з облікового запису</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import GAButton from "../GAButton/GAButton.vue";
import { getUserInfo } from "@/services/userAuthInfo";
import axios from "axios";
import InfoBanner from "./InfoBanner.vue";

const isVisible = ref(false);
const closeNav = () => {
  isVisible.value = !isVisible.value;
};

const userData = ref(null);
const userDataLoaded = ref(false);
onMounted(async () => {
  try {
    const data = await getUserInfo();
    userData.value = data;
  } catch (error) {
    console.log(error);
  } finally {
    userDataLoaded.value = true;
  }
});

const handleLogout = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api/logout`);
    window.location.reload();
    return await response.data;
  } catch (error) {
    console.error(error);
  }
};
</script>

<script>
export default {
  name: "HeaderComponent",
  computed: {
    isInProfile() {
      return this.$route.name == 'UserCabinet';
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-title {
  text-transform: uppercase;
  font-family: $secondary-font !important;
  font-weight: 900;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.profile-photo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.currenttab {
  text-decoration: underline;
}
</style>
