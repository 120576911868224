<template>
  <!-- Display content of the template -->
  <div class="template__content">
    <span>
      <span class="template__content__title">РОЗПИСКА</span>
      <br />
      <span class="template__content__title">про отримання завдатку</span>
      <br />
      <br />
      Дана розписка складена в м.
      <span :class="{ active: props.currentStep === 2 }">
        {{ depositData.form.location || "_________" }}
      </span>
      <br />
      <br />
      Я,
      <span :class="{ active: currentStep === 3 }">
        {{ depositData.form.username || "(ПІБ)" }}
      </span>
      ,
      <span :class="{ active: currentStep === 4 }">
        {{ formattedDate(depositData.form.userBirthday) || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: currentStep === 5 }">
        {{ depositData.form.userPassportSeries || "_____" }}
      </span>
      виданий
      <span :class="{ active: currentStep === 6 }">
        {{ formattedDate(depositData.form.userPassportDate) || "__________________" }}
      </span>
      р., зареєстрований(-a) та проживаю за адресою:
      <span :class="{ active: currentStep === 7 }">
        {{ depositData.form.userAddress || "__________________" }}
      </span>
      , отримав від
      <span :class="{ active: currentStep === 8 }">
        {{ getGenitive(depositData.form.secondUsername) || "(ПІБ)" }}
      </span>
      ,
      <span :class="{ active: currentStep === 9 }">
        {{ formattedDate(depositData.form.secondUserBirthday) || "_____" }}
      </span>
      року народження, паспорт серії
      <span :class="{ active: currentStep === 10 }">
        {{ depositData.form.secondUserPassportSeries || "_______ " }}
      </span>
      виданий
      <span :class="{ active: currentStep === 11 }">
        {{ formattedDate(depositData.form.secondUserPassportDate) || "__________________" }}
      </span>
      р., зареєстрований(-a) та проживаючий(-a) за адресою:
      <span :class="{ active: currentStep === 12 }">
        {{ depositData.form.secondUserAddress || "____________" }}
      </span>
      , завдаток в сумі
      <span :class="{ active: currentStep === 13 }">
        {{ depositData.form.loanAmountNumber || "______" }}
      </span>
      грн
      <span :class="{ active: currentStep === 14 }">
        {{ depositData.form.loanAmountNumberCoin || "___" }}
      </span>
      коп. (
      <span :class="{ active: currentStep === 15 }">
        {{ depositData.form.loanAmountString || "_____________" }}
      </span>
      гривень
      <span :class="{ active: currentStep === 16 }">
        {{ depositData.form.loanAmountStringCoin || "_______" }}
      </span>
      копійок), в рахунок оплати за відчуження належної мені квартири (будинку), що знаходиться за
      адресою
      <span :class="{ active: currentStep === 17 }">
        {{ depositData.form.apartmentAddres || "__________________" }}
      </span>
      . Зобов’язуюсь укласти угоду купівлі-продажу даного майна до
      <span :class="{ active: currentStep === 18 }">
        {{ formattedDate(depositData.form.purchaseDate) || "______________" }}
      </span>
      року, в разі порушення строків укладення угоди або не укладення її зовсім зобов’язуюсь
      повернути всю суму отриманого завдатку з урахуванням суми штрафу в розмірі
      <span :class="{ active: currentStep === 19 }">
        {{ depositData.form.depositFine || "______" }}
      </span>
      грн. В разі не укладення угоди купівлі-продажу з боку
      <span>
        {{ getGenitive(depositData.form.secondUsername) || "(ПІБ)" }}
      </span>
      , сума завдатку залишається в моєму повному володінні та розпорядженні.
      <br />
      <span>
        Розписка буде мати юридичну силу після передачі грошей від
        {{ getInitials(depositData.form.secondUsername) || "ПІБ" }} до
        {{ getInitials(depositData.form.username) || "ПІБ" }}
      </span>
      <br />
      <br />
      <span class="template__content__last-row">
        <span :class="{ active: currentStep === 20 }">
          {{ formattedDate(depositData.form.docDate) || "_____________" }} року
        </span>
        <img class="template__content__signImg" :src="depositData.form.userSignature" alt="" />
        <span :class="{ active: currentStep === 3 }">
          {{ getInitials(depositData.form.username) || "_________________________" }}
        </span>
      </span>
      <span class="template__content__last-row">
        <div class="support-width"></div>
        <img
          class="template__content__signImg"
          :src="depositData.form.secondUserSignature"
          alt=""
        />
        <span :class="{ active: currentStep === 8 }">
          {{ getInitials(depositData.form.secondUsername) || "_________________________" }}</span
        >
      </span>
      <span v-if="depositData.form.completedDate">
        Розписку виконано в повному обсязі. Сторони не мають претензій одна до одної.
      </span>
      <br />
      <span v-if="!depositData.form.completedDate" class="complete">
        <router-link to="/template/zavdatok">
          <Button text="Підтвердити виконану розписку" />
        </router-link>
      </span>
      <br />
      <div v-if="depositData.form.completedDate" class="row">
        <br />
        <span class="template__content__last-row">
          {{ formattedDate(depositData.form.completedDate) || "_________" }} року
          <img class="template__content__signImg" :src="depositData.form.userSignature" alt="" />
          <span>{{ getInitials(depositData.form.username) || "_________________________" }}</span>
        </span>
        <span class="template__content__last-row">
          <div class="support-width"></div>
          <img
            class="template__content__signImg"
            :src="depositData.form.secondUserSignature"
            alt=""
          />
          <span>{{
            getInitials(depositData.form.secondUsername) || "_________________________"
          }}</span>
        </span>
      </div>
      <br />
    </span>
  </div>
</template>

<script setup>
import { defineProps } from "vue"
import { getGenitive, getInitials } from "@/services/nameCases"
import { useDepositStore } from "@/stores/depositStore"
import Button from "@/components/Button/Button.vue"
import { formattedDate } from "@/services/formattedDate"

const depositData = useDepositStore()

const props = defineProps({
  currentStep: {
    type: Number,
  },
})
</script>

<style lang="scss" scoped>
.complete {
  font-family: $main-font;
}
.active {
  background-color: $bpLight-1;
}
</style>
