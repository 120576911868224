<template>
  <div
    class="card text-center"
    ref="target"
    :style="{ transform: cardTransform, transition: 'transform 0.25 ease-out' }">
    <div class="card-body d-flex flex-column align-items-center">
      <img
        class="card-img-top"
        style="width: 200px; margin-top: -100px; margin-bottom: -0.75em"
        :src="require(`./images/${imageUrl}`)"
        alt="" />
      <h5 class="card__title">{{ props.title }}</h5>
      <div class="card__separator"></div>
      <p class="card-text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { useMouseInElement } from "@vueuse/core";

const target = ref(null);

const { elementX, elementY, isOutside, elementHeight, elementWidth } = useMouseInElement(target);

const cardTransform = computed(() => {
  const MAX_ROTATION = 6;

  const rX = (MAX_ROTATION / 2 - (elementY.value / elementHeight.value) * MAX_ROTATION).toFixed(2);

  const rY = ((elementX.value / elementWidth.value) * MAX_ROTATION - MAX_ROTATION / 2).toFixed(2);

  return isOutside.value ? "" : `perspective(${elementWidth.value}px) rotateX(${rX}deg) rotateY(${rY}deg)`;
});

const props = defineProps({
  title: String,
  text: String,
  imageUrl: String,
});
</script>

<style scoped lang="scss">
@import "./assets/styles.scss";
</style>
