<template>
  <button
    class="btn btn-primary"
    style="border: none; border-radius: 35px; padding: 10px 30px; background-color: #4066f6">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: String,
  },
};
</script>

<style lang="scss">
.btns {
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 30px;
  box-shadow: 0px 5px 10px 0px rgba(158, 153, 218, 0.6);
  color: #fff;
  cursor: pointer;
}

.btn:hover {
  box-shadow: 0px 5px 10px 0px rgba(158, 153, 218, 0.6);
}

.btn:active {
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}
</style>
