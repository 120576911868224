<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__content__column">
          <h3 class="footer__title">Шаблони розписок</h3>
          <div class="footer__router">
            <router-link
              style="text-decoration: none; color: #0f0d15"
              v-for="template in templates"
              :key="template.id"
              :to="{ name: 'Template', params: { id: template.name } }">
              <p class="">{{ template.title }}</p>
            </router-link>
          </div>
        </div>

        <p class="footer__content__column">
          <a style="text-decoration: none; color: #0f0d15" href="/#diia" class="nav-link"> Про Дія.Підпис </a>
        </p>

        <div class="footer__content__column">
          <p>Слідкуйте за нами в соцмережах</p>
          <div class="footer__content__column__social-list">
            <a :href="this.linkTelegram">
              <font-awesome-icon icon="fa-brands fa-telegram" class="footer__content__column__social-icon--telegram" />
            </a>
            <a :href="this.linkYoutube">
              <font-awesome-icon icon="fa-brands fa-youtube" class="footer__content__column__social-icon" />
            </a>
            <a :href="this.linkInstagram">
              <font-awesome-icon icon="fa-brands fa-instagram" class="footer__content__column__social-icon" />
            </a>
            <a :href="this.linkFacebook">
              <font-awesome-icon icon="fa-brands fa-facebook-f" class="footer__content__column__social-icon" />
            </a>
            <a :href="this.linkTikTok">
              <font-awesome-icon icon="fa-brands fa-tiktok" class="footer__content__column__social-icon" />
            </a>
          </div>
          <!-- <form class="footer__content__form" @submit.prevent="">
						<input class="footer__content__form__input" 
							type="email" 
							placeholder="Введіть Email"
						/>
						<font-awesome-icon icon="fa-regular fa-envelope" v-if="isMobile" class="footer__content__form__btn" />
						<Button v-else
							text="Отримувати новини"
							class="footer__content__form__btn"
						/>
					</form> -->
        </div>
      </div>
      <div class="footer__separator"></div>

      <div class="footer__content">
        <div class="d-flex align-items-center gap-3">
          <img class="footer__logo-icon" src="@/assets/icons/logo_rozpyskaWhite.svg" alt="logo" />
          <div class="d-flex flex-column gap-0">
            <p class="m-1" style="font-size: 0.875rem">rozpyska.com.ua</p>
            <p class="m-1" style="font-size: 0.875rem">© 2023. Усі права захищено</p>
          </div>
        </div>
        <router-link to="/policy" class="footer__content__column" style="text-decoration: none; color: #0f0d15">
          Політика конфіденційності
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import { getLink } from "../../services/LinkService.js";
// import Button from "../Button/Button.vue";

export default {
  name: "FooterComponent",
  // components: { Button },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data: () => ({
    linkTelegram: undefined,
    linkYoutube: undefined,
    linkInstagram: undefined,
    linkFacebook: undefined,
    linkTikTok: undefined,
    templates: [
      {
        name: "orenda",
        title: "Оренда житла",
        id: 1,
      },
      {
        name: "zavdatok",
        title: "Передача завдатку",
        id: 2,
      },
      {
        name: "loan",
        title: "Гроші в борг",
        id: 3,
      },
      {
        name: "individual",
        title: "Додати свій шаблон",
        id: 4,
      },
    ],
  }),
  async created() {
    // Retrieving the link for social media using the getLink method
    this.linkTelegram = await this.getLink("telegram");
    this.linkYoutube = await this.getLink("youtube");
    this.linkInstagram = await this.getLink("instagram");
    this.linkFacebook = await this.getLink("facebook");
    this.linkTikTok = await this.getLink("tiktok");
  },
  methods: {
    // Method that calls the getLink function with the resource argument and returns the link from the database
    async getLink(resource) {
      let l = await getLink(resource);
      return l.link;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/styles.scss";
</style>
